import React, { useContext, useEffect, useState } from 'react';
import { Web3Context } from '../Components/Web3Context/Web3Context';
import Round from '../Components/Round/Round';
import { ExoPresaleRound, ExoPresaleTransaction } from '../Common/Models';
import ImageExoLogo from '../Images/exo-grad-logo.png';
import ImageCryptonairzLogo from '../Images/cryptonairz-logo.png';
import { useSearchParams } from 'react-router-dom';
import { roundAndFormatNumber } from '../Common/HelperFunctions';

function HomePage() {
    const { presaleTransactions, presaleRounds, contribute, connectWeb3, disconnectWeb3, web3Connected, walletAddress, setReferrerString, busdApprovalAmount, approveBusd, busdBalance } = useContext(Web3Context);
    const [searchParams, setSearchParams] = useSearchParams();

    function getTransactionForRound(round: number): ExoPresaleTransaction[] {
        var ret: ExoPresaleTransaction[] = [];
        ret = presaleTransactions.filter(x => x.round.toString() == round.toString());
        return ret;
    }

    useEffect(() => {
        var referrer = searchParams.get("ref");
        // console.log(`REFERRER: ${referrer}`);

        if (referrer != null) {
            setReferrerString(referrer.toLowerCase());
        }
    }, [presaleRounds]);

    const whitelistedRounds = presaleRounds.filter(x => x.whitelisted && x.closed == false).sort((a, b) => Number(b.special) - Number(a.special));
    const closedRounds = presaleRounds.filter(x => ((x.special && x.whitelisted) || (x.special == false)) && x.closed == true /*&& presaleTransactions.filter(y => y.round == x.number).length > 0*/);

    return (
        <>
            <main className="flex flex-col w-100 flex-1 relative focus:outline-none">
                {/* <h1 className="text-primary-500  text-left text-3xl font-bold">Contributions</h1> */}

                <div className="flex mx-auto mt-5 md:mt-10 drop-shadow-2xl">
                    <img src={ImageExoLogo} className="h-14 -mt-4 flex-col" />
                    <div className="border-l-4 border-white h-full mx-2 md:mx-8 -mt-2"></div>
                    <div className="text-gradient-horizontal text-4xl -mt-2 ">PRESALE ROUNDS</div>
                </div>
                <div className="mx-auto text-white mt-4 px-2 drop-shadow-2xl">Use this dashboard to check the status and contribute BUSD to the exo funding rounds.</div>

                {busdBalance > 0 ? <div className="mx-auto text-white mt-4 px-2 drop-shadow-2xl"><b>Your BUSD balance:</b> ${roundAndFormatNumber(busdBalance, 2)} BUSD.</div> : null}

                <div className="w-full mt-6">
                    {
                        whitelistedRounds.filter(x => x.name == "CRYPTONAIRZ").length > 0 ? <div className="flex p-5 rounded-xl bg-black bg-opacity-20 text-center p-8 px-4 md:px-10 my-8">
                            <img src={ImageCryptonairzLogo} className="h-10 object-contain -mt-1 md:h-24 flex-col" />
                            <div className="text-left text-xl flex-grow ml-10">
                                <div className="text-white font-bold">Welcome to our friends from Cryptonairz!</div>
                                <div className="text-white text-sm">As a valued partner of exo, Cryptonairz members are entitled to an exclusive funding round. Access to this round is through a custom whitelist provided by the Crytponairz team.</div>
                                {/* <div className="text-gray-500"><b>VESTING:</b> {props.round.vesting}</div> */}
                            </div>
                        </div> : null
                    }

                    {
                        whitelistedRounds.length > 0 ? whitelistedRounds.map(r => (
                            <div key={"presaleround_" + r.number}><Round round={r} contributeFunction={contribute} transactions={getTransactionForRound(r.number)} busdAllowance={busdApprovalAmount} busdApproveFunction={approveBusd} />
                            </div>)) : null
                    }

                    {
                        closedRounds.length > 0 ? closedRounds.map(r => (
                            <div key={"presaleround_" + r.number}><Round round={r} contributeFunction={contribute} transactions={getTransactionForRound(r.number)} busdAllowance={busdApprovalAmount} busdApproveFunction={approveBusd} />
                            </div>)) : null
                    }

                    {
                        web3Connected == false ? <div className="mx-auto text-white font-bold p-5 rounded-xl bg-black bg-opacity-20 text-center p-8 px-4 md:px-10 my-8">
                            Connect your wallet to get started.
                        </div> : null
                    }

                    {
                        web3Connected == true && whitelistedRounds.length == 0 ? <div className="mx-auto text-white font-bold p-5 rounded-xl bg-black bg-opacity-20 text-center p-8 px-4 md:px-10 my-8">
                            Unfortunately your wallet is not currently whitelisted for an exo presale round. <a href="https://discord.gg/exofi" target="_blank" className='underline'>Click here</a> to head to our discord for information.
                        </div> : null}
                </div>
            </main>
        </>
    );
}

export default HomePage;