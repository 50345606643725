import React, { FC, useEffect, useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { abbreviateAddress, getTimeUntil, getYearMonthDayString, round, roundAndFormatNumber } from "../../Common/HelperFunctions";
import { ExoPresaleRound, ExoPresaleTransaction } from "../../Common/Models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare, faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ImageLoading } from '../../Images/loading-animation.svg';

interface RoundProps {
    className?: string;
    innerClassName?: string;

    transactions: ExoPresaleTransaction[];
    round: ExoPresaleRound;
    contributeFunction: (round: number, amount: number, reference: string) => Promise<void>;

    busdAllowance: number;
    busdApproveFunction: () => Promise<void>;
}


const Round: FC<RoundProps> = (props) => {

    const inputName = "contribute_txt_" + props.round.number;

    const [showTransactions, setShowTransactions] = useState<boolean>(false);
    const [pendingAction, setPendingAction] = useState<boolean>(false);
    const [timeString, setTimeString] = useState<string>("");
    const [timerEnabled, setTimerEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (round != null && timerEnabled == false) {
            setInterval(() => { setTimeString(getTimeString(props.round)) }, 1500);
            setTimerEnabled(true);
        }
    }, [props.round]);

    function contribute() {
        if (pendingAction == true) return;

        console.log(pendingAction);
        var inputElem = document.getElementById(inputName) as HTMLInputElement;
        var value = inputElem.value;
        var number = Number(value);

        if (number > 0) {
            console.log(`Contributing ${number} BUSD...`);

            setPendingAction(true);
            props.contributeFunction(props.round.number, number, "")
                .then((x) => { console.log("Then") })
                .catch(err => { console.log("Error"); console.error(err) })
                .finally(() => { console.log("Finally"); setPendingAction(false) });
        }
    }

    async function approveBusd() {
        if (pendingAction == true) return;

        setPendingAction(true);
        console.log("Approving busd...");
        try { await props.busdApproveFunction(); } catch (ex) { console.error(ex) }

        setPendingAction(false);
    }

    function getColorForRound(round: ExoPresaleRound): string {
        var ret = "#14E1D5";
        if (round.number == 0) ret = "#14E1D5";
        else if (round.number == 1) ret = "#5763DB";
        else if (round.number == 2) ret = "#CC448B";
        else if (round.number == 3) ret = "#EF7C2F";

        if (round.special == true) ret = "#FFD700";
        // if (round.closed == true) ret = "#808080";
        return ret;
    }

    function getTimeString(round: ExoPresaleRound): string {
        var ret = "00:00:00";
        var start = new Date();
        var end = new Date(round.activeFrom);

        var t = getTimeUntil(new Date(), round.activeFrom);
        ret = `${t.days < 10 ? "0" + t.days : t.days}:${t.hours < 10 ? "0" + t.hours : t.hours}:${t.minutes < 10 ? "0" + t.minutes : t.minutes}:${t.seconds < 10 ? "0" + roundAndFormatNumber(t.seconds, 0) : roundAndFormatNumber(t.seconds, 0)}`;
        return ret;
    }

    const totalContribution = props.transactions.reduce<number>((p, c) => p + c.amountAbsolute, 0);

    return (
        <div key={`presaleRound_${props.round.number}_container`} className={"p-5 rounded-xl bg-black bg-opacity-20 text-center p-8 px-4 md:px-10 my-8 " + (" " + props.round.closed ? "text-white" : "text-white") + " " + (props.round.special ? "border-gold-500 border-2" : "")}>
            <div className="w-full h-full grid md:grid-cols-4 grid-cols-2 gap-4">
                <div className="text-left text-xl">
                    <div className="text-white font-bold">{props.round.name}</div>
                    <div className="text-gray-500 text-md"><b>ROUND PRICE:</b> ${roundAndFormatNumber(props.round.price, 4)}</div>
                    {/* <div className="text-gray-500"><b>VESTING:</b> {props.round.vesting}</div> */}
                </div>
                <div>
                    <div>SOLD: ${roundAndFormatNumber(props.round.balance)}</div>
                    <div className="bg-white bg-opacity-10 w-full h-4 my-1 rounded-full">
                        <div className={`h-full rounded-full`} style={{ "width": `${(props.round.balance / props.round.maximum * 100)}%`, backgroundColor: getColorForRound(props.round) }}></div>
                    </div>
                    <div className="font-bold" style={{ color: getColorForRound(props.round) }}>AVAILABLE: ${roundAndFormatNumber(props.round.maximum - props.round.balance)}</div>
                </div>
                <div className="h-full hover:cursor-pointer" onClick={() => setShowTransactions(!showTransactions)}>
                    <div className="bg-black bg-opacity-20 rounded-xl py-4 text-lg font-bold" >
                        <div className="">YOUR CONTRIBUTION:</div>
                        <div>${roundAndFormatNumber(totalContribution)} BUSD</div>
                    </div>
                    {showTransactions == false ? <div className="rounded-xl w-10 m-auto">
                        <FontAwesomeIcon icon={faAngleDown} className="text-white " />
                    </div> : null}
                </div>
                <div className="">
                    {props.round.activeFrom < new Date() ?
                        props.round.closed == false ?
                            props.busdAllowance > 250 ? <>
                                <input type="text" id={inputName} className="text-white bg-dark1-500 block w-5/6 mx-auto bg-opacity-20 rounded rounded-md m-2 h-8 p-4 sm:text-sm" placeholder="Amount (min 250)" />
                                <button className={"gradientbutton font-bold"} onClick={contribute}>{pendingAction ? <ImageLoading className="animate-spin h-5 w-5 text-white" /> : "DEPOSIT BUSD"}</button></>
                                : <>
                                    <button className={"gradientbutton font-bold"} onClick={approveBusd}>{pendingAction ? <ImageLoading className="animate-spin h-5 w-5 text-white" /> : "APPROVE BUSD"}</button>
                                </>
                            : <>
                                <button className={"gradientbutton font-bold disabled:gradientbuttondisabled"} onClick={() => { }} disabled={true}>ROUND CLOSED</button>
                            </> : <>
                            <button className={"gradientbutton font-bold disabled:gradientbuttondisabled"} onClick={() => { }} disabled={true}>OPENS IN {timeString}</button>
                        </>}
                </div>
            </div>

            {showTransactions == true ? <div className="w-full pt-12 overflow-x-scroll md:overflow-x-auto">
                <table className="md:w-full md:min-w-full divide-y divide-gray-300 text-left">
                    <thead>
                        <tr>
                            <th scope="col" className="py-3.5 text-left text-sm font-semibold sm:pl-6 md:pl-0 pr-1">Date</th>
                            <th scope="col" className="py-3.5 text-left text-sm font-semibold sm:pl-6 md:pl-0 pr-1">Deposit Amount (USD)</th>
                            <th scope="col" className="py-3.5 text-left text-sm font-semibold sm:pl-6 md:pl-0 pr-1">Vested Amount (EXO)</th>
                            <th scope="col" className="py-3.5 text-left text-sm font-semibold sm:pl-6 md:pl-0 pr-1">Transaction</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {props.transactions.sort((a, b) => a.timestamp > b.timestamp ? -1 : 1).map(x =>
                            <tr key={"txrow_" + x.transactionHash + x.transactionIndex + x.transactionLogIndex}>
                                <td className="whitespace-nowrap py-4 text-sm font-medium sm:pl-6 md:pl-0 pr-1">{getYearMonthDayString(x.timestamp)}</td>
                                <td className="whitespace-nowrap py-4 text-sm font-medium sm:pl-6 md:pl-0 pr-1">${roundAndFormatNumber(x.amountAbsolute)}</td>
                                <td className="whitespace-nowrap py-4 text-sm font-medium sm:pl-6 md:pl-0 pr-1">{roundAndFormatNumber(x.amountAbsolute / props.round.price)} EXO</td>
                                <td className="whitespace-nowrap py-4 text-sm font-medium sm:pl-6 md:pl-0 pr-1"><a href={`https://bscscan.com/tx/${x.transactionHash}`} target="_blank">{abbreviateAddress(x.transactionHash, 4, 4)}&nbsp;&nbsp;<FontAwesomeIcon icon={faUpRightFromSquare} /></a></td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div className="bg-black bg-opacity-20 rounded-xl w-10 m-auto hover:cursor-pointer" onClick={() => setShowTransactions(false)}>
                    <FontAwesomeIcon icon={faAngleUp} className="text-white " />
                </div>
            </div>
                : null}
        </div>
    );
};

export default Round;